 .chatPage {
     height: inherit;
     margin-bottom: 24px;
     border-radius: 12px;
 }

 .cs-main-container .cs-sidebar.cs-sidebar--left {
     gap: 24px;
     padding-right: 24px;
 }


 .cs-main-container .cs-conversation-header {
     background: none;
 }

 .cs-conversation-header__content .cs-conversation-header__user-name {
     background: none;
 }


 .cs-message-group--outgoing .cs-message-group__messages .cs-message:only-child .cs-message__content,
 .cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
     background-color: rgba(82, 76, 187, 0.2);
     border-radius: 8px !important;
 }

 .cs-message-group--incoming .cs-message-group__messages .cs-message:only-child .cs-message__content,
 .cs-message-group--incoming .cs-message-group__messages .cs-message .cs-message__content {
     background-color: #EEEEEE;
     border-radius: 8px !important;
 }

 .cs-conversation-header__content .cs-conversation-header__info {
     background: none;
 }


 .cs-chat-container .cs-message-input {
     height: 104px;
 }


 .cs-message-input__content-editor-container {
     padding: 10px;
     background: none;
     border: solid 2px #d1dbe3;
     border-radius: 6px;
 }

 .cs-message-input__content-editor {
     background: none;
 }

 .cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:last-child {
     background: none;
 }



 .cs-conversation:hover {
     background-color: #eeeeee96;
 }

 .cs-conversation__name {
     font-size: 14px;
     font-weight: 600;
     font-family: inherit;
 }

 .cs-conversation__info-content {
     font-size: 12px;
     font-weight: 400;
     font-family: inherit;
     color: rgba(136, 136, 136, 1);
 }


 .cs-chat-container .cs-message-input .cs-message-input__content-editor-wrapper:first-child {
     margin: 0px;
     background: none;
 }

 .cs-conversation.cs-conversation--active {
     background-color: rgba(82, 76, 187, 0.2);
     border-radius: 6px;
 }

 .cs-conversation.cs-conversation--active .cs-conversation__name {
     color: black;
 }

 .cs-conversation__last-activity-time {
     padding-left: 64px;
     margin-right: -10px;
     font-size: 12px;
     font-family: inherit;
     color: black;
 }

 .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
     background-color: green;
 }

 .onload {
     display: flex;
     margin: auto;
     justify-content: center;
     height: max-content;
     font-size: 18px;
     font-weight: 600;
     color: #888888;
 }

 .onChatLoad {
     display: flex;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     justify-content: center;
     height: max-content;
     font-size: 18px;
     font-weight: 600;
     color: #888888;

 }

 .cs-message-input__tools {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }

 .cs-conversation-list>div>ul {
     display: flex;
     flex-direction: column;
     gap: 5px;
 }

 .cs-conversation__unread-dot {
     background: #524CBB;

 }

 .cs-message-separator {
     color: #524CBB;
 }

 .chat_closed {
     opacity: 0.5;
 }


 .cs-message-list .cs-message.cs-message--outgoing,
 .cs-message-list .cs-message-group.cs-message-group--outgoing {
     margin-bottom: 6px !important;
 }