.randomClass1 {
    height: auto;
    max-height: calc(100vh - 128px);
    overflow-y: scroll;
}

.randomClass1::-webkit-scrollbar {
    display: none;
}

.conta {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(367px, 1fr));
    grid-gap: 32px;
}

.box {
    border-radius: 5px;
}