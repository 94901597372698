.login-card {
    background: rgba(255, 255, 255, 1);
    width: 520px;
}

.logoFrame {
    width: 142px;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 4.12px;
}