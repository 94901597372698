.card {
    overflow: auto;
}

.card::-webkit-scrollbar {
    width: 10px;
    height: 0px;
}

.card::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
}