.MuiFormHelperText-root.Mui-error {
    margin: initial;
}

.MuiPagination-ul {
    justify-content: end;
}

.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    font-size: 14px;
}

.MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: none;
}


.MuiInputBase-root-MuiOutlinedInput-root {
    margin-bottom: 2px;
}

.pagination-limit-select {
    width: fit-content !important;
}