.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    width: 264px;
    background-color: #110030 !important;
}

.css-174vfuo-MuiPaper-root {
    width: 264px;
    background-color: #110030 !important;
}

.contain {
    width: 264px;
    background-color: #110030 !important;
}

.activeHover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.listItem {
    padding: 12px;
}

.listItem:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
}

.heightOverflow {
    height: auto;
    max-height: calc(100vh - 128px);
    overflow-y: scroll;
}

.heightOverflow::-webkit-scrollbar {
    display: none;
}